import React from "react";
import { Link } from 'react-router-dom';
import i18next from "i18next";
import { getSavedEmail } from "../helpers/authUtils";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import superagent from "superagent";
import {AppContext} from "../App";

class RecoverPasswordPage extends React.Component {

    state = {
        general_error: null
    }

    handleSubmit = async (values, { setErrors }) => {
        this.setState({general_error: null})

        superagent
            .put(AppContext.hostName + '/v1/users/recover')
            .send(JSON.stringify(values))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.props.history.push('/login');
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 400:
                            this.setState({general_error: res.body.message})
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    }

    render = () => {
        const startYear = 2021;
        const currentYear = new Date().getFullYear();
        let labelYear = startYear.toString()
        if (currentYear !== startYear) {
            labelYear = startYear.toString() + " - " + currentYear.toString()
        }

        return (
            <React.Fragment>
                <div className="accountbg" style={{ background : "url('/assets/images/bg-login.jpg')",backgroundSize : "cover" }}/>

                <div className="wrapper-page account-page-full">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/" onClick={()=> this.props.UpdateLoginAgain()}  className="logo logo-admin"><img src="/assets/images/logo.svg" height="90" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">{i18next.t("recover.title")}</h4>
                                <p className="text-muted text-center">{i18next.t("recover.subtitle")}</p>

                                <Formik
                                    className="form-wrap"
                                    initialValues={{
                                        email: getSavedEmail() ? getSavedEmail() : ""
                                    }}
                                    onSubmit={this.handleSubmit}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email(i18next.t("error.invalid-email"))
                                            .required(i18next.t("error.field-required"))
                                    })}
                                >
                                    {props => {
                                        return (
                                            <Form className="form-horizontal m-t-30">
                                                {this.state.general_error &&
                                                <div className="alert alert-danger m-t-30" role="alert">
                                                    <strong>{i18next.t("error.general")}</strong> {this.state.general_error}
                                                </div>
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="email">{i18next.t("login.email")}</label>
                                                    <Field type="email" className="form-control" name="email" placeholder={i18next.t("login.email-placeholder")} />
                                                    <ErrorMessage name="email" className="error-message" component="div" />
                                                </div>

                                                <div className="form-group row m-t-20">
                                                    <div className="col-12 text-right">
                                                        <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{i18next.t("recover.submit")}</button>
                                                    </div>
                                                </div>

                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>

                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p className="">{i18next.t("recover.remember-it")} <Link to="/login" className="font-500 font-14 font-secondary">{i18next.t("recover.sign-in")}</Link> </p>
                        <p className="">{i18next.t("general.copyright", {labelYear: labelYear})}</p>
                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default RecoverPasswordPage;