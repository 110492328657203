import React from "react";

class NotFoundPage extends React.Component {

    render = () => {

        return (
            <React.Fragment>
                <div>Not Found Page</div>
            </React.Fragment>
        )
    }
}

export default NotFoundPage;