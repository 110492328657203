import React from "react";
import i18next from "i18next";

class HomePage extends React.Component {

    render = () => {

        return (
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="ex-page-content text-center">
                                            <h1>{i18next.t("home.title")}</h1>
                                            <h3>{i18next.t("home.subtitle")}</h3><br></br>
                                            <a href="https://astrid-bigot.fr" target="_blank" rel="noreferrer nopener" className="btn btn-primary mb-5 waves-effect waves-light">{i18next.t("home.button")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default HomePage;