import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount(){
        let currentPage = this.capitalizeFirstLetter(this.props.location.pathname);
        document.title = currentPage + " | Astrid Bigot";
    }

    render() {
        return (
            <main>
                <div id="wrapper">
                    <Sidebar  />
                    <div className="content-page">
                        <div className="content">
                            <Header parent={this} />
                            {this.props.children}
                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        );
    }
}

export default (withRouter(Layout));