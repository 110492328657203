import React from "react";
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {AppContext} from "../App";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {getLoggedInUser} from "../helpers/authUtils";
import i18next from "i18next";
import superagent from "superagent";

class AddressForm extends React.Component {

    state = {
        address: null,
        slim: null
    };

    constructor(props) {
        super(props);
        this.state.address = this.props.address;
    }

    handleResponse = (err, res, setErrors, setSubmitting) => {
        if (res === undefined) {
            this.setState({general_error: err.message})
        } else {
            switch (res.status) {
                case 200:
                    this.props.load();
                    this.props.toggle(true);
                    break;
                case 400:
                    const error = JSON.parse(res.text);
                    error.fields.forEach(function(field) {
                        if (field.name === "name") {
                            setErrors({ "name_en": field.message });
                        } else {
                            setErrors({ [field.name]: field.message });
                        }
                    });
                    break;
                //case 401:
                //    this.props.history.push('/login');
                //    break;
                default:
                    this.setState({general_error: "Response status: " + res.status})
                    break;
            }
        }
        setSubmitting(false);
    }

    /*
type InputAddress struct {
	ID         string  `json:"id"`
	Floor      string  `json:"floor"`
	Building   string  `json:"building"`
	Street     string  `json:"street"`
	Locality   string  `json:"locality"`
	PostalCode string  `json:"postal_code"`
	City       string  `json:"city"`
	Country    string  `json:"country"`
	Access     string  `json:"access"`
	GoogleMap  string  `json:"google_map"`
	Latitude   float64 `json:"latitude"`
	Longitude  float64 `json:"longitude"`
}
     */
    handleSubmit = (values, { setErrors, setSubmitting }) => {
        console.log(values);

        if (this.state.address.id !== "") {
            superagent
                .put(AppContext.hostName + '/v1/addresses/' + this.state.address.id)
                .send(JSON.stringify(values))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        } else {
            superagent
                .post(AppContext.hostName + '/v1/addresses')
                .send(JSON.stringify(values))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        }
    };

    getTitle = () => {
        if (this.state.address.id === "") {
            return (
                i18next.t("general.add-new-modal-title")
            );
        } else {
            return (
                i18next.t("general.update-modal-title")
            );
        }
    };

    /*
            currentAddress: {
                "id": "",
                "access": "",
                "building": "",
                "city": "",
                "country": "",
                "floor": "",
                "google_map": "",
                "latitude": 0.0,
                "locality": "",
                "longitude": 0.0,
                "postal_code": "",
                "street": ""
            },
     */
    render = () => {
        const AddressSchema = Yup.object().shape({
            access: Yup.string(),
            building: Yup.string(),
            city: Yup.string().required(i18next.t("error.field-required")),
            country: Yup.string().required(i18next.t("error.field-required")),
            floor: Yup.string(),
            locality: Yup.string(),
            postal_code: Yup.string().required(i18next.t("error.field-required")),
            street: Yup.string().required(i18next.t("error.field-required")),
        });

        const initValues = {};
        initValues.access = this.state.address != null ? this.state.address.access : "";
        initValues.building = this.state.address != null ? this.state.address.building : "";
        initValues.city = this.state.address != null ? this.state.address.city : "";
        initValues.country = this.state.address != null ? this.state.address.country : "";
        initValues.floor = this.state.address != null ? this.state.address.floor : "";
        initValues.locality = this.state.address != null ? this.state.address.locality : "";
        initValues.postal_code = this.state.address != null ? this.state.address.postal_code : "";
        initValues.street = this.state.address != null ? this.state.address.street : "";

        return (
            <Formik
                initialValues={initValues}
                validationSchema={AddressSchema}
                onSubmit={this.handleSubmit}
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => (
                    <Form>

                        <ModalHeader toggle={this.props.toggle}>
                            {this.getTitle()}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.general_error &&
                            <div className="alert alert-danger" role="alert">
                                <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                            </div>
                            }

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-6">
                                        <label>{i18next.t("address.building")}</label>
                                        <Field name={"building"} className="form-control"
                                               value={values['building']}
                                               onChange={(e) => setFieldValue("building", e.target.value)}
                                        />
                                        <ErrorMessage name={"building"} component="div" className="error-message"/>
                                    </div>

                                    <div className="col-6">
                                        <label>{i18next.t("address.floor")}</label>
                                        <Field name={"floor"} className="form-control"
                                               value={values['floor']}
                                               onChange={(e) => setFieldValue("floor", e.target.value)}
                                        />
                                        <ErrorMessage name={"floor"} component="div" className="error-message"/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("address.street")}</label>
                                <Field name={"street"} className="form-control"
                                       value={values['street']}
                                       onChange={(e) => setFieldValue("street", e.target.value)}
                                />
                                <ErrorMessage name={"street"} component="div" className="error-message"/>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("address.locality")}</label>
                                <Field name={"locality"} className="form-control"
                                       value={values['locality']}
                                       onChange={(e) => setFieldValue("locality", e.target.value)}
                                />
                                <ErrorMessage name={"locality"} component="div" className="error-message"/>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-6">
                                        <label>{i18next.t("address.postal_code")}</label>
                                        <Field name={"postal_code"} className="form-control"
                                               value={values['postal_code']}
                                               onChange={(e) => setFieldValue("postal_code", e.target.value)}
                                        />
                                        <ErrorMessage name={"postal_code"} component="div" className="error-message"/>
                                    </div>

                                    <div className="col-6">
                                        <label>{i18next.t("address.city")}</label>
                                        <Field name={"city"} className="form-control"
                                               value={values['city']}
                                               onChange={(e) => setFieldValue("city", e.target.value)}
                                        />
                                        <ErrorMessage name={"city"} component="div" className="error-message"/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("address.country")}</label>
                                <Field name={"country"} className="form-control"
                                       value={values['country']}
                                       onChange={(e) => setFieldValue("country", e.target.value)}
                                />
                                <ErrorMessage name={"country"} component="div" className="error-message"/>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("address.access")}</label>
                                <Field name={"access"} className="form-control"
                                       value={values['access']}
                                       onChange={(e) => setFieldValue("access", e.target.value)}
                                />
                                <ErrorMessage name={"access"} component="div" className="error-message"/>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                outline disabled={isSubmitting}
                                onClick={this.props.toggle}
                            >
                                {i18next.t("general.cancel")}
                            </Button>
                            <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                {isSubmitting && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {i18next.t("general.submit")}
                            </Button>{" "}
                        </ModalFooter>
                    </Form>

                )}
            </Formik>
        )
    }
}

AddressForm.propTypes = {
    address: PropTypes.object,
    load: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
};

export default AddressForm;