import React from "react";
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {AppContext} from "../App";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {getLoggedInUser} from "../helpers/authUtils";
import i18next from "i18next";
import Slim from "../components/slim/slim.react";
import superagent from "superagent";

class TestimonialForm extends React.Component {

    state = {
        testimonial: null,
        slim: null,
        currentPicture: null
    };

    constructor(props) {
        super(props);
        this.state.testimonial = this.props.testimonial;
    }

    handleResponse = (err, res, setErrors, setSubmitting) => {
        if (res === undefined) {
            this.setState({general_error: err.message})
        } else {
            switch (res.status) {
                case 200:
                    this.props.load();
                    this.props.toggle(true);
                    break;
                case 400:
                    const error = JSON.parse(res.text);
                    error.fields.forEach(function(field) {
                        if (field.name === "name") {
                            setErrors({ "name_en": field.message });
                        } else {
                            setErrors({ [field.name]: field.message });
                        }
                    });
                    break;
                case 401:
                    this.props.history.push('/login');
                    break;
                default:
                    this.setState({general_error: "Response status: " + res.status})
                    break;
            }
        }
        setSubmitting(false);
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        let item = {
            "name": values['name'],
            "message": values['message'],
            "action": 0,
            "base64": "",
        }

        console.log(this.state.slim.dataBase64.output.name);
        // if picture deleted
        if (this.state.slim.dataBase64.output.name == null) {
            item["action"] = 2;
        } else {
            if (this.state.slim.dataBase64.output.image !== this.state.currentPicture) {
                item["action"] = 1;
                item["base64"] = this.state.slim.dataBase64.output.image;
            }
        }

        console.log(item["action"]);

        if (this.state.testimonial.id !== "") {
            superagent
                .put(AppContext.hostName + '/v1/testimonials/' + this.state.testimonial.id)
                .send(JSON.stringify(item))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        } else {
            superagent
                .post(AppContext.hostName + '/v1/testimonials')
                .send(JSON.stringify(item))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        }
    };

    getTitle = () => {
        if (this.state.testimonial.id === "") {
            return (
                i18next.t("general.add-new-modal-title")
            );
        } else {
            return (
                i18next.t("general.update-modal-title")
            );
        }
    };

    slimInit(data, slim) {
        this.setState({
            slim: slim,
            currentPicture: slim.dataBase64 == null ? null : slim.dataBase64.output.image
        });
    }

    render = () => {
        const TestimonialSchema = Yup.object().shape({
            name: Yup.string().required(i18next.t("error.field-required")),
            message: Yup.string().required(i18next.t("error.field-required"))
        });

        const initValues = {};
        initValues.name = this.state.testimonial != null ? this.state.testimonial.name : "";
        initValues.message = this.state.testimonial != null ? this.state.testimonial.message : "";

        return (
            <Formik
                initialValues={initValues}
                validationSchema={TestimonialSchema}
                onSubmit={this.handleSubmit}
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => (
                    <Form>

                        <ModalHeader toggle={this.props.toggle}>
                            {this.getTitle()}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.general_error &&
                            <div className="alert alert-danger" role="alert">
                                <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                            </div>
                            }

                            <div className="form-group">
                                <label>{i18next.t("testimonial.name")}</label>
                                <Field name={"name"} className="form-control"
                                       value={values['name']}
                                       onChange={(e) => setFieldValue("name", e.target.value)}
                                />
                                <ErrorMessage name={"name"} component="div" className="error-message"/>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("testimonial.message")}</label>
                                <Field name={"message"} className="form-control" component="textarea" rows="6"
                                       value={values['message']}
                                       onChange={(e) => setFieldValue("message", e.target.value)}
                                />
                                <ErrorMessage name={"message"} component="div" className="error-message"/>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("testimonial.image")}</label>
                                <Slim
                                    ratio={"600:600"}
                                    initialImage={this.state.testimonial.image}
                                    push={false}
                                    download={true}
                                    label={i18next.t("slim.drop-here")}
                                    buttonEditTitle={i18next.t("slim.edit")}
                                    buttonRemoveTitle={i18next.t("slim.remove")}
                                    buttonDownloadTitle={i18next.t("slim.download")}
                                    buttonUploadTitle={i18next.t("slim.upload")}
                                    buttonRotateTitle={i18next.t("slim.rotate")}
                                    buttonCancelLabel={i18next.t("slim.cancel")}
                                    buttonCancelTitle={i18next.t("slim.cancel")}
                                    buttonConfirmLabel={i18next.t("slim.confirm")}
                                    buttonConfirmTitle={i18next.t("slim.confirm")}
                                    statusUploadSuccess={i18next.t("slim.saved")}
                                    minSize={{width: 570, height: 430}}
                                    serviceFormat="file"
                                    didInit={ this.slimInit.bind(this) }
                                >
                                    <input type="file" name="foo"/>
                                </Slim>

                                <ErrorMessage name="genre" component="div" className="error-message"/>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                outline disabled={isSubmitting}
                                onClick={this.props.toggle}
                            >
                                {i18next.t("general.cancel")}
                            </Button>
                            <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                {isSubmitting && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {i18next.t("general.submit")}
                            </Button>{" "}
                        </ModalFooter>
                    </Form>

                )}
            </Formik>
        )
    }
}

TestimonialForm.propTypes = {
    testimonial: PropTypes.object,
    load: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
};

export default TestimonialForm;