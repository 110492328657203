import React from "react";
import i18next from "i18next";
import {Button, ModalFooter, ModalHeader, ModalBody} from "reactstrap";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from "yup";
import superagent from "superagent";
import {AppContext} from "../App";
import {getLoggedInUser} from "../helpers/authUtils";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import 'react-notifications/lib/notifications.css';

class PractitionerPage extends React.Component {

    state = {
        general_error: "",
        parameters: null
    };

    componentDidMount() {
        superagent
            .get(AppContext.hostName + '/v1/parameters')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        parameters: res.body
                    })
                } else {
                    console.error(err);
                }
            });
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        superagent
            .put(AppContext.hostName + '/v1/parameters/practitioner')
            .send(JSON.stringify(values))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res) => {
                if (res === undefined) {
                    this.setState({general_error: err.message})
                } else {
                    switch (res.status) {
                        case 200:
                            NotificationManager.success(i18next.t("parameter.update-success"));
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                }
                setSubmitting(false);
            });
    };

    render = () => {
        if (this.state.parameters == null) {
            return null
        }

        const PractitionerSchema = Yup.object().shape({
            name: Yup.string().required(i18next.t("error.field-required")),
            email: Yup.string().required(i18next.t("error.field-required")),
            phone: Yup.string().required(i18next.t("error.field-required"))
        });

        const initValues = {};
        initValues.name = this.state.parameters.practitioner.name;
        initValues.email = this.state.parameters.practitioner.email;
        initValues.phone = this.state.parameters.practitioner.phone;

        return (
            <React.Fragment>
                <NotificationContainer/>

                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">

                                <div className="card m-b-20">
                                    <div className="card-body">

                                        <Formik
                                            initialValues={initValues}
                                            validationSchema={PractitionerSchema}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {({ values, setFieldValue, isValid, isSubmitting }) => (
                                                <Form>

                                                    <ModalHeader toggle={this.props.toggle}>
                                                        {i18next.t("practitioner.title")}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        {this.state.general_error &&
                                                        <div className="alert alert-danger" role="alert">
                                                            <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                                                        </div>
                                                        }

                                                        <p className="text-muted m-b-30">{i18next.t("practitioner.help")}</p>

                                                        <div className="form-group">
                                                            <label>{i18next.t("practitioner.name")}</label>
                                                            <p className="text-muted m-b-15">{i18next.t("practitioner.name-help")}</p>
                                                            <Field name={"name"} className="form-control"
                                                                   value={values['name']}
                                                                   onChange={(e) => setFieldValue("name", e.target.value)}
                                                            />
                                                            <ErrorMessage name={"name"} component="div" className="error-message"/>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>{i18next.t("practitioner.email")}</label>
                                                            <p className="text-muted m-b-15">{i18next.t("practitioner.email-help")}</p>
                                                            <Field name={"email"} className="form-control"
                                                                   value={values['email']}
                                                                   onChange={(e) => setFieldValue("email", e.target.value)}
                                                            />
                                                            <ErrorMessage name={"email"} component="div" className="error-message"/>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>{i18next.t("practitioner.phone")}</label>
                                                            <p className="text-muted m-b-15">{i18next.t("practitioner.phone-help")}</p>
                                                            <Field name={"phone"} className="form-control"
                                                                   value={values['phone']}
                                                                   onChange={(e) => setFieldValue("phone", e.target.value)}
                                                            />
                                                            <ErrorMessage name={"phone"} component="div" className="error-message"/>
                                                        </div>

                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                                            {isSubmitting && (
                                                                <i
                                                                    className="fa fa-refresh fa-spin"
                                                                    style={{ marginRight: "5px" }}
                                                                />
                                                            )}
                                                            {i18next.t("general.modify")}
                                                        </Button>{" "}
                                                    </ModalFooter>
                                                </Form>

                                            )}
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PractitionerPage;