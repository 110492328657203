import React from "react";
import { Link } from 'react-router-dom';
import i18next from "i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import superagent from "superagent";
import {AppContext} from "../App";

class ResetPasswordPage extends React.Component {

    state = {
        general_error: null
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {

        const item = {
            "password": values["password1"]
        }

        superagent
            .put(AppContext.hostName + '/v1/users/' + this.props.match.params.token + '/reset')
            .send(JSON.stringify(item))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.props.history.push('/login');
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 400:
                            this.setState({general_error: res.body.message})
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    render = () => {

        return (
            <React.Fragment>
                <div className="accountbg" style={{ background : "url('/assets/images/bg-login.jpg')",backgroundSize : "cover" }}></div>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/"  className="logo logo-admin"><img src="/assets/images/logo.svg" height="90" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">{i18next.t("reset.title")}</h4>
                                <p className="text-muted text-center">{i18next.t("reset.subtitle")}</p>

                                <Formik
                                    className="form-wrap"
                                    initialValues={{
                                        password1: "",
                                        password2: ""
                                    }}
                                    onSubmit={this.handleSubmit}
                                    validationSchema={Yup.object().shape({
                                        password1: Yup.string().required(i18next.t("error.field-required")),
                                        password2: Yup.string().required(i18next.t("error.field-required")).when("password1", {
                                            is: val => (val && val.length > 0),
                                            then: Yup.string().oneOf(
                                                [Yup.ref("password1")],
                                                i18next.t("error.same-password")
                                            )
                                        })
                                    })}
                                >
                                    {props => {
                                        return (
                                            <Form className="form-horizontal m-t-30" >
                                                {this.state.general_error &&
                                                <div className="alert alert-danger m-t-30" role="alert">
                                                    <strong>{i18next.t("error.general")}</strong> {this.state.general_error}
                                                </div>
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="password1">{i18next.t("reset.password1")}</label>
                                                    <Field type="password" className="form-control" name="password1" placeholder={i18next.t("reset.password1-placeholder")} />
                                                    <ErrorMessage name="password1" className="error-message" component="div" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="password2">{i18next.t("reset.password2")}</label>
                                                    <Field type="password" className="form-control" name="password2" placeholder={i18next.t("reset.password2-placeholder")} />
                                                    <ErrorMessage name="password2" className="error-message" component="div" />
                                                </div>

                                                <div className="form-group row m-t-20">
                                                    <div className="col-12 text-right">
                                                        <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{i18next.t("reset.submit")}</button>
                                                    </div>
                                                </div>

                                            </Form>
                                        );
                                    }}
                                </Formik>

                            </div>

                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p className="">{i18next.t("recover.remember-it")} <Link to="/login" className="font-500 font-14 font-secondary"> {i18next.t("recover.sign-in")} </Link> </p>
                        <p className="">{i18next.t("general.copyright")}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ResetPasswordPage;