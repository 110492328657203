import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class NoLayout extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount() {
        let currentPage = this.capitalizeFirstLetter(this.props.location.pathname);
        document.title = currentPage + " | Astrid Bigot";
    }

    render() {
        return (
            <main>
                {this.props.children}
            </main>
        );
    }
}

export default (withRouter(NoLayout));