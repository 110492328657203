import React from "react";
import i18next from "i18next";
import classNames from "classnames";

class FAQPage extends React.Component {

    InitFrench = () => {
        return [
            {
                "id": 1,
                "question": "Comment modifier l'adresse de mail publiée sur le site",
                "answer": "Modifier l'adresse de messagerie dans Général > Praticien."
            },
            {
                "id": 2,
                "question": "Comment modifier le numéro de téléphone publié sur le site",
                "answer": "Modifier le numéro de téléphone dans Général > Praticien."
            }
        ]
    }

    render = () => {

        const questions = this.InitFrench();

        return (
            <React.Fragment>
                <div className="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">{i18next.t("faq.title")}</h4>
                                        <div className="m-t-30">
                                            <div id="accordion">
                                                {questions.map((q, index) => {
                                                    return (
                                                        <div key={index} className="card">
                                                            <div className="card-header bg-white border-bottom-0 p-3" id="headingOne">
                                                                <h5 className="mb-0 mt-0 font-16 font-light">
                                                                    <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                                       aria-controls="collapseOne" className="text-dark">
                                                                        Q.{q.id} {q.question}
                                                                    </a>
                                                                </h5>
                                                            </div>

                                                            <div id="collapseOne" className={classNames("collapse", {"show": q.id === 1})}
                                                                 aria-labelledby="headingOne" data-parent="#accordion">
                                                                <div className="card-body text-muted">{q.answer}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default FAQPage;