import i18next from "i18next";
import {locales} from "./locales/locales";

let language = navigator.language.substring(0, 2);
if (localStorage.getItem('trip-frontend-language') != null) {
    language = localStorage.getItem('trip-frontend-language')
}

i18next.init({
    lng: language,
    fallbackLng: 'en',
    debug: false,
    resources: locales
}, function(err, t) {
    // initialized and ready to go!
});
