import React from "react";
import superagent from "superagent";
import {AppContext} from "../App";
import i18next from "i18next";
import {Modal} from "reactstrap";
import ScheduleForm from "../forms/ScheduleForm";
import SweetAlert from "react-bootstrap-sweetalert";
import {getLoggedInUser} from "../helpers/authUtils";

class SchedulePage extends React.Component {

    state = {
        general_error: "",
        schedule: null,
        addresses: null,
        currentSchedule: null
    }

    componentDidMount = () => {
        this.getSchedule();
        this.getAddresses()
    };

    getSchedule = () => {
        superagent
            .get(AppContext.hostName + '/v1/schedule')
            .forceUpdate(true)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        general_error: "",
                        schedule: res.body,
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    getAddresses = () => {
        superagent
            .get(AppContext.hostName + '/v1/addresses')
            .forceUpdate(true)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        general_error: "",
                        addresses: res.body,
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    handleModal = () => {
        this.setState({createModalOpened: !this.state.createModalOpened})
    }

    updateSchedule = (schedule) => {
        this.setState({
            currentSchedule: schedule,
            createModalOpened: true
        })
    }

    createSchedule = () => {
        this.setState({
            currentSchedule: {
                "day": "",
                "id": "",
                "address": "",
                "start": "08:00",
                "finish": "18:00"
            },
            createModalOpened: true
        })
    }

    handleDelete = () => {
        superagent
            .delete(AppContext.hostName + '/v1/schedule')
            .send(JSON.stringify(this.state.currentSchedule))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res) => {
                if (err == null) {
                    this.setState({deleteAlertOpened: false}, () => {
                        this.getSchedule();
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        case 406:
                            this.setState({
                                general_error: i18next.t("category.impossible_deletion"),
                                deleteAlertOpened: false
                            })
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    renderTimeSlot = (day, slot) => {
        const addr = this.state.addresses.filter(address => address.id === slot.address)[0];

        return (
            <tr>
                <td>{slot.start} - {slot.finish}</td>
                <td>{addr.city}</td>
                <td>
                    <button type="button"
                            onClick={() => {
                                this.updateSchedule({
                                    "day": day,
                                    "id": slot.id,
                                    "address": slot.address,
                                    "start": slot.start,
                                    "finish": slot.finish
                                })
                            }}
                            className="btn btn-primary waves-effect waves-light">
                        <i className="mdi mdi-pencil font-18"/>
                    </button>
                    <span> </span>
                    <button type="button"
                            onClick={() => {
                                this.setState({
                                    currentSchedule: {
                                        "day": day,
                                        "id": slot.id,
                                        "address": slot.address,
                                        "start": slot.start,
                                        "finish": slot.finish
                                    },
                                    deleteAlertOpened: true
                                })
                            }}
                            className="btn btn-blue-grey waves-effect waves-light">
                        <i className="mdi mdi-close font-18"/>
                    </button>
                </td>
            </tr>
        )
    }

    renderDay = (day) => {
        return (
            <React.Fragment>
                <div className="row mb-2">
                    <div className="col-6">
                        <h2>{i18next.t("schedule." + day.name)}</h2>
                    </div>

                    <div className="col-6 d-flex justify-content-end">
                        <button type="button"
                                onClick={() => {
                                    this.createSchedule()
                                }}
                                style={{height: '36px'}}
                                className="btn btn-primary waves-effect waves-light">
                            <i className="mdi mdi-plus font-18"/>
                        </button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                <table id="datatable"
                                       className="table table-striped dt-responsive nowrap table-vertical"
                                       width="100%" cellSpacing="0">

                                    {day.time_slots.map(slot => {
                                        return (
                                            this.renderTimeSlot(day.name, slot)
                                        )
                                    })}

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

    render = () => {
        if ((this.state.schedule == null) || (this.state.addresses == null)) {
            return null
        }

        return (
            <React.Fragment>
                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row m-b-10">
                            <div className="col-12">
                                {this.state.general_error !== "" &&
                                <div className="alert alert-danger mb-0" role="alert">
                                    <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                                </div>
                                }
                            </div>
                        </div>

                        {this.state.schedule.days.map(day => {
                            return (
                                this.renderDay(day)
                            )
                        })}

                    </div>
                </div>

                <Modal isOpen={this.state.createModalOpened} toggle={this.handleModal}>
                    <ScheduleForm
                        schedule={this.state.currentSchedule}
                        load={this.getSchedule}
                        toggle={this.handleModal}/>
                </Modal>

                {this.state.deleteAlertOpened ?
                    <SweetAlert
                        title={i18next.t("schedule.delete")}
                        warning
                        showCancel
                        onConfirm={() => {
                        }}
                        customButtons={
                            <React.Fragment>
                                <button type="button"
                                        onClick={() => this.setState({deleteAlertOpened: false})}
                                        className="btn btn-blue-grey waves-effect waves-light">{i18next.t("general.no")}
                                </button>
                                <span>&nbsp;</span>
                                <button type="button"
                                        onClick={() => this.handleDelete()}
                                        className="btn btn-primary waves-effect waves-light">{i18next.t("general.yes")}
                                </button>
                            </React.Fragment>
                        }
                    >
                        {i18next.t("schedule." + this.state.currentSchedule.day)}
                        <span> </span>
                        {this.state.currentSchedule.start}
                        <span> - </span>
                        {this.state.currentSchedule.finish}
                    </SweetAlert> : null}

            </React.Fragment>
        )
    }
}

export default SchedulePage;