import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { unsetLoggedInUser } from '../helpers/authUtils';
import i18next from "i18next";

class header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownLanguage: false,
            dropdownOpenProfile: false,
            now_route : ""
        };

        this.toggleLanguage = this.toggleLanguage.bind(this);
        this.toggleProfile = this.toggleProfile.bind(this);
    }

    toggleLanguage() {
        this.setState(prevState => ({
            dropdownLanguage: !prevState.dropdownLanguage
        }));
    }

    toggleProfile() {
        this.setState(prevState => ({
            dropdownOpenProfile: !prevState.dropdownOpenProfile
        }));
    }

    toggleScreen(e) {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    componentDidMount() {
        this.setState({now_route :this.props.location.pathname })
    }

    handleLogout = () => {
        unsetLoggedInUser();
        this.props.history.push('/login');
    }

    setLanguage = (lang) => {
        i18next.changeLanguage(lang).then((r) => {
            console.log(this.props.parent);
            this.props.parent.forceUpdate()
        });
    }

    render() {
        return (
            <div className="topbar">
                <nav className="navbar-custom">

                    <ul className="list-inline float-right mb-0">
                        <li className="list-inline-item dropdown notification-list hidden-xs-down">
                            <Link  onClick={() => this.toggleScreen()}  className="nav-link waves-effect" to="#" id="btn-fullscreen">
                                <i className="mdi mdi-fullscreen noti-icon"/>
                            </Link>
                        </li>
                        <li className="list-inline-item dropdown notification-list hidden-xs-down">

                            <Dropdown isOpen={this.state.dropdownLanguage}  toggle={this.toggleLanguage}>
                                <DropdownToggle  className="nav-link dropdown-toggle arrow-none waves-effect text-muted" tag="a">
                                    <span style={{color:"#707070"}}>{i18next.t("language." + i18next.language)}</span> <img src={"/assets/images/flags/flag_" + i18next.language + ".jpg"} className="ml-2" height="16" alt="" />
                                </DropdownToggle>
                                <DropdownMenu className='dropdown-menu dropdown-menu-right language-switch'>
                                    <div className="dropdown-item" onClick={() => this.setLanguage("en")}><img src="/assets/images/flags/flag_en.jpg" alt="" height="16"/><span> {i18next.t("language.en")} </span></div>
                                    <div className="dropdown-item" onClick={() => this.setLanguage("fr")}><img src="/assets/images/flags/flag_fr.jpg" alt="" height="16"/><span> {i18next.t("language.fr")} </span></div>
                                </DropdownMenu>
                            </Dropdown>

                        </li>
                        <li className="list-inline-item dropdown notification-list">
                            <Dropdown isOpen={this.state.dropdownOpenProfile}  toggle={this.toggleProfile}>
                                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                                    <img src="/assets/images/users/avatar.svg" alt="user" className="rounded-circle" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.handleLogout}><i className="mdi mdi-power text-danger"/> {i18next.t("menu.logout")}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    </ul>

                    <ul className="list-inline menu-left mb-0">
                        <li className="list-inline-item">
                            <button type="button" className="button-menu-mobile open-left waves-effect">
                                <i className="ion-navicon"/>
                            </button>
                        </li>
                        <li className="hide-phone list-inline-item app-search">
                            <h3 className="page-title" id="now_routing"> </h3>
                        </li>
                    </ul>
                    <div className="clearfix"/>
                </nav>
            </div>

        );
    }
}

export default withRouter(header);