import React, {createContext} from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import Layout from "./layouts/Layout";
import NoLayout from "./layouts/NoLayout";
import superagent from 'superagent';
import superagentCache from 'superagent-cache';

import './App.css';

export const AppContext = createContext({
    hostName: 'http://localhost:8080',
});

class App extends React.Component {

    constructor(props) {
        super(props);

        superagentCache(superagent);

        AppContext.hostName = 'http://localhost:8080';

        if (process.env.NODE_ENV === 'production') {
            //AppContext.hostName = 'https://astrid-bigot.osc-fr1.scalingo.io'
            AppContext.hostName = 'https://astrid.dm-labs.org';
        }
    }

    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <Switch>

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                                layout={Layout}
                                key={idx}
                                isAuthProtected={true}
                            />
                        ))}

                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                                layout={NoLayout}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                    </Switch>
                </BrowserRouter>

            </React.Fragment>
        );
    }
}

export default App;
