import React from "react";
import i18next from "i18next";
import {AppContext} from "../App";
import Pagination from "../components/Pagination";
import moment from "moment";
import 'moment/locale/fr';
import {Modal} from "reactstrap";
import {getLoggedInUser} from "../helpers/authUtils";
import SweetAlert from 'react-bootstrap-sweetalert';
import superagent from 'superagent';
import PaginationSizes from "../components/PaginationSizes";
import UserForm from "../forms/UserForm";

class UsersPage extends React.Component {

    state = {
        general_error: "",
        selectedPageSize: 10,
        currentPage: 1,
        totalItemCount: 0,
        totalPage: 1,
        search: "",
        users: [],
        currentUser: null,
        createModalOpened: false,
        deleteAlertOpened: false
    };

    componentDidMount = () => {
        this.dataListRender();
    };

    dataListRender = () => {
        superagent
            .get(AppContext.hostName + '/v1/users')
            .query({ detailed: true })
            .query({ pageSize: this.state.selectedPageSize })
            .query({ currentPage: this.state.currentPage.toString() })
            .query({ search: this.state.search })
            .query({ orderBy: "name" })
            .forceUpdate(true)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        general_error: "",
                        totalPage: parseInt(res.headers['x-pagination-page-count'], 10),
                        users: res.body,
                        totalItemCount: parseInt(res.headers['x-pagination-total-count'], 10),
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.setState({general_error: "Response status: " + res.status})
                            //this.props.history.push('/login');
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    handleChangePageSize = (size) => {
        this.setState({selectedPageSize: size}, () => {
            this.dataListRender()
        });
    }

    handleChangePage = (page) => {
        this.setState({currentPage: page}, () => {
            this.dataListRender()
        })
    }

    handleSearch = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.setState({search: event.currentTarget.value}, () => {
                this.dataListRender()
            })
        }
    }

    handleModal = () => {
        this.setState({createModalOpened: !this.state.createModalOpened})
    }

    updateUser = (user) => {
        this.setState({
            currentUser: user,
            createModalOpened: true
        })
    }

    createUser = () => {
        this.setState({
            currentUser: {
                "id": "",
                "name": ""
            },
            createModalOpened: true
        })
    }

    handleDelete = () => {
        superagent
            .delete(AppContext.hostName + '/v1/users/' + this.state.currentUser.id)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res) => {
                if (err == null) {
                    this.setState({deleteAlertOpened: false}, () => {
                        this.dataListRender();
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        case 406:
                            this.setState({
                                general_error: i18next.t("user.impossible_deletion"),
                                deleteAlertOpened: false
                            })
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    handleBlock = (user) => {
        superagent
            .put(AppContext.hostName + '/v1/users/' + user.id + "/block")
            .send(JSON.stringify(user))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res) => {
                if (res === undefined) {
                    this.setState({general_error: err.message})
                } else {
                    switch (res.status) {
                        case 200:
                            this.dataListRender();
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                }
            });
    }

    handleUnBlock = (user) => {
        superagent
            .put(AppContext.hostName + '/v1/users/' + user.id + "/unblock")
            .send(JSON.stringify(user))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res) => {
                if (res === undefined) {
                    this.setState({general_error: err.message})
                } else {
                    switch (res.status) {
                        case 200:
                            this.dataListRender();
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                }
            });
    }

    renderStatus = (status) => {
        switch (status) {
            case 10: // not verified
                return (
                    <span className="badge badge-default">{i18next.t("user.status-not-verified")}</span>
                )
            case 20: // active
                return (
                    <span className="badge badge-success">{i18next.t("user.status-active")}</span>
                )
            case 30: // max attempts
                return (
                    <span className="badge badge-warning">{i18next.t("user.status-max-attempts")}</span>
                )
            case 40: // blocked
                return (
                    <span className="badge badge-danger">{i18next.t("user.status-blocked")}</span>
                )
            default:
                return (
                    <span className="badge badge-dark">{i18next.t("user.status-unknown")}</span>
                )
        }
    }

    renderBlockButton = (user) => {
        if (user.status > 20) {
            return (
                <button type="button"
                        onClick={() => {
                            this.handleUnBlock(user)
                        }}
                        className="btn btn-success waves-effect waves-light">
                    <i className="mdi mdi-play-circle font-18"/>
                </button>
            )
        } else {
            return (
                <button type="button"
                        onClick={() => {
                            this.handleBlock(user)
                        }}
                        className="btn btn-danger waves-effect waves-light">
                    <i className="mdi mdi-stop-circle font-18"/>
                </button>
            )
        }
    }

    render = () => {
        moment.locale(i18next.language);

        return (
            <React.Fragment>

                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row m-b-10">
                            <div className="col-12">
                                {this.state.general_error !== "" &&
                                <div className="alert alert-danger mb-0" role="alert">
                                    <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                                </div>
                                }
                            </div>
                        </div>

                        <div className="row m-b-10">
                            <div className="col-6">
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                        <div className="input-group">
                                            <input className="form-control" type="text" id={"search_input"}
                                                   placeholder={i18next.t("user.placeholder-search")}
                                                   onKeyUp={this.handleSearch}
                                            />
                                            <span
                                                onClick={() => {
                                                    document.getElementById('search_input').value = '';
                                                    this.setState({search: ""}, () => {
                                                        this.dataListRender()
                                                    })
                                                }}
                                                className="input-group-text">{i18next.t("general.clear")}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-6 d-flex justify-content-end">
                                <button type="button"
                                        onClick={() => {
                                            this.createUser()
                                        }}
                                        style={{height: '36px'}}
                                        className="btn btn-primary waves-effect waves-light">
                                    <i className="mdi mdi-plus font-18"/>
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">
                                        <table id="datatable"
                                               className="table table-striped dt-responsive nowrap table-vertical"
                                               width="100%" cellSpacing="0">
                                            <thead>
                                            <tr>
                                                <th>{i18next.t("user.lastname")}</th>
                                                <th>{i18next.t("user.firstname")}</th>
                                                <th>{i18next.t("user.status")}</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.users.map(user => {
                                                return (
                                                    <tr key={user.id}>
                                                        <td>{user.last_name}</td>
                                                        <td>{user.first_name}</td>
                                                        <td>{this.renderStatus(user.status)}</td>
                                                        <td>
                                                            <button type="button"
                                                                    onClick={() => {
                                                                        this.updateUser(user)
                                                                    }}
                                                                    className="btn btn-primary waves-effect waves-light">
                                                                <i className="mdi mdi-pencil font-18"/>
                                                            </button>
                                                            <span> </span>
                                                            {this.renderBlockButton(user)}
                                                            <span> </span>
                                                            <button type="button"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            currentUser: user,
                                                                            deleteAlertOpened: true
                                                                        })
                                                                    }}
                                                                    className="btn btn-blue-grey waves-effect waves-light">
                                                                <i className="mdi mdi-close font-18"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row m-b-20">
                            <div className="col-4">
                                <span>{i18next.t("general.rows-per-page")} </span>
                                <PaginationSizes size={this.state.selectedPageSize} onChange={this.handleChangePageSize} />
                            </div>
                            <div className="col-4 text-center">
                                {i18next.t("general.rows", {
                                    start: (this.state.currentPage - 1) * parseInt(this.state.selectedPageSize, 10) + 1,
                                    finish: Math.min((this.state.currentPage) * parseInt(this.state.selectedPageSize, 10), this.state.totalItemCount),
                                    total: this.state.totalItemCount
                                })}
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                <Pagination
                                    currentPage={this.state.currentPage}
                                    totalPage={this.state.totalPage}
                                    onChangePage={this.handleChangePage}
                                />
                            </div>
                        </div>


                    </div>
                </div>

                <Modal isOpen={this.state.createModalOpened} toggle={this.handleModal}>
                    <UserForm
                        user={this.state.currentUser}
                        load={this.dataListRender}
                        toggle={this.handleModal}/>
                </Modal>

                {this.state.deleteAlertOpened ?
                    <SweetAlert
                        title={i18next.t("user.delete")}
                        warning
                        showCancel
                        onConfirm={() => {
                        }}
                        customButtons={
                            <React.Fragment>
                                <button type="button"
                                        onClick={() => this.setState({deleteAlertOpened: false})}
                                        className="btn btn-blue-grey waves-effect waves-light">{i18next.t("general.no")}
                                </button>
                                <span>&nbsp;</span>
                                <button type="button"
                                        onClick={() => this.handleDelete()}
                                        className="btn btn-primary waves-effect waves-light">{i18next.t("general.yes")}
                                </button>
                            </React.Fragment>
                        }
                    >
                        {this.state.currentUser.first_name} {this.state.currentUser.last_name}
                    </SweetAlert> : null}

            </React.Fragment>

        )
    }

}

export default UsersPage;