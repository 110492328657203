import React, {Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';

import 'react-perfect-scrollbar/dist/css/styles.css';
import i18next from "i18next";

class sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Tab: 'home', SubTab: '', MoreTab: '',  dashboard_menu:false, email_menu : false, ui_menu : false,
            form_menu: false, chart_menu: false, table_menu: false, icon_menu: false, map_menu: false,
            auth_menu: false, extra_menu: false, eco_menu: false, emt_menu: false
        };
    }

    setActiveTab = (tab,subtab,moretab,toggleTab, e)=> {
        this.setState({Tab: tab,SubTab: subtab,MoreTab: moretab});
    }

    componentDidMount() {
        let now_route = "";
        let pageUrl = window.location.pathname.split(/[?#]/)[0];
        now_route = pageUrl.substr(1).replace(/_/g," ");
        $('#now_routing').empty();
        if(now_route === "") { now_route="home" } else {  }
        $('#now_routing').append(now_route);

        this.setState({Tag: now_route})

        $('.toggle-search').on('click', function () {
            let targetId = $(this).data('target');
            let $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });

        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $("body").toggleClass("enlarged");
        });

        $('li.has_sub li').on('click', function (event) {
            $("body").toggleClass("enlarged");
        });
    }

    componentDidUpdate() {
        let now_route = "";
        let pageUrl = window.location.pathname.split(/[?#]/)[0];
        now_route = pageUrl.substr(1).replace("_"," ");
        $('#now_routing').empty();
        if(now_route === "") { now_route="home" } else {  }
        $('#now_routing').append(now_route);
        //this.setState({Tag: now_route})
    }

    render() {
        return (
            <div className="left side-menu">

                <div className="topbar-left">
                    <div className="">
                        <Link to="/" className="logo"><img src="/assets/images/logo-white.svg" height="60" alt="logo" /></Link>
                    </div>
                </div>

                <div className="sidebar-inner slimscrollleft" >
                    <PerfectScrollbar>
                        <div id="sidebar-menu">
                            <ul>
                                <li className="menu-title">{i18next.t("menu.main")}</li>

                                <li>
                                    <Link to="/home" className={this.state.Tab === 'home' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'home','','')}><i className="mdi mdi-view-dashboard"/><span> {i18next.t("menu.home")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/users" className={this.state.Tab === 'users' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'users','','')}><i className="mdi mdi-account-multiple"/><span> {i18next.t("menu.users")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/site" className={this.state.Tab === 'site' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'site','','')}><i className="mdi mdi-apple-safari"/><span> {i18next.t("menu.site")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/practitioner" className={this.state.Tab === 'practitioner' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'practitioner','','')}><i className="mdi mdi-account"/><span> {i18next.t("menu.practitioner")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/addresses" className={this.state.Tab === 'addresses' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'addresses','','')}><i className="mdi mdi-account-location"/><span> {i18next.t("menu.addresses")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/schedule" className={this.state.Tab === 'schedule' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'schedule','','')}><i className="mdi mdi-calendar"/><span> {i18next.t("menu.schedule")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/testimonials" className={this.state.Tab === 'calendar' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'calendar','','')}><i className="mdi mdi-text-to-speech"/><span> {i18next.t("menu.testimonials")} </span></Link>
                                </li>

                                <li className="menu-title">{i18next.t("menu.blog")}</li>

                                <li>
                                    <Link to="/categories" className={this.state.Tab === 'categories' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'categories','','')}><i className="mdi mdi-calendar-check"/><span> {i18next.t("menu.blog-categories")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/tags" className={this.state.Tab === 'tags' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'tags','','')}><i className="mdi mdi-tag"/><span> {i18next.t("menu.blog-tags")} </span></Link>
                                </li>

                                <li>
                                    <Link to="/posts" className={this.state.Tab === 'posts' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'posts','','')}><i className="mdi mdi-comment-text"/><span> {i18next.t("menu.blog-posts")} </span></Link>
                                </li>

                                <li className="menu-title">{i18next.t("menu.help")}</li>

                                <li>
                                    <Link to="/faq" className={this.state.Tab === 'faq' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'faq','','')}><i className="mdi mdi-help-circle"/><span> {i18next.t("menu.faq")} </span></Link>
                                </li>

                            </ul>
                        </div>

                        <div className="clearfix"/>
                    </PerfectScrollbar>
                </div>

            </div>
        );
    }
}

export default withRouter(sidebar);