import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

class Pagination extends React.Component {

    render() {

        let startPoint;
        let endPoint;

        if (this.props.numberLimit > this.props.totalPage) {
            startPoint = 1;
            endPoint = this.props.totalPage;
        } else if (this.props.currentPage <= Math.trunc(this.props.numberLimit / 2)) {
            startPoint = 1;
            endPoint = this.props.numberLimit;
        } else if (this.props.currentPage + Math.trunc(this.props.numberLimit / 2) <= this.props.totalPage) {
            startPoint = this.props.currentPage - Math.trunc(this.props.numberLimit / 2);
            endPoint = startPoint + this.props.numberLimit - 1;
        } else {
            startPoint = this.props.totalPage - (this.props.numberLimit - 1);
            endPoint = this.props.totalPage;
        }
        startPoint = startPoint === 0 ? 1 : startPoint;

        const points = [];
        for (let i = startPoint; i <= endPoint; i++) {
            points.push(i);
        }

        return (
            <nav aria-label="Page navigation">
                <ul className="pagination">

                    {this.props.firstIsActive && (
                        <li className={
                            classNames('page-item',
                                {disabled: this.props.currentPage === 1},
                            )}>
                            <div className="page-link first"
                                onClick={() => this.props.onChangePage(1)}
                            >
                                <i className="ti-control-skip-backward"/>
                            </div>
                        </li>
                    )}


                    <li className={
                        classNames('page-item',
                            {disabled: this.props.currentPage === 1},
                        )}>
                        <div className="page-link first"
                            onClick={() => this.props.onChangePage(this.props.currentPage - 1)}
                        >
                            <i className="ti-control-backward"/>
                        </div>
                    </li>


                    {points.map(i => {
                        return (
                            <li key={i}
                                className={
                                    classNames('page-item',
                                        {active: i === this.props.currentPage},
                                    )
                                }>
                                <div className="page-link"
                                    onClick={() => this.props.onChangePage(i)}
                                >
                                    {i}
                                </div>
                            </li>
                        );
                    })}

                    <li className={
                        classNames('page-item',
                            {disabled: this.props.currentPage === this.props.totalPage},
                        )}>
                        <div className="page-link last"
                            onClick={() => this.props.onChangePage(this.props.currentPage + 1)}
                        >
                            <i className="ti-control-forward"/>
                        </div>
                    </li>

                    {this.props.lastIsActive && (
                        <li className={
                            classNames('page-item',
                                {disabled: this.props.currentPage === this.props.totalPage},
                            )}>
                            <div className="page-link last"
                                onClick={() => this.props.onChangePage(this.props.totalPage)}
                            >
                                <i className="ti-control-skip-forward"/>
                            </div>
                        </li>
                    )}

                </ul>
            </nav>
        )
    }
}

Pagination.defaultProps = {
    numberLimit: 5,
    firstIsActive: true,
    lastIsActive: true
};

Pagination.propTypes = {
    numberLimit: PropTypes.number,
    totalPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    firstIsActive: PropTypes.bool,
    lastIsActive: PropTypes.bool
}

export default Pagination;