import React from 'react';
import i18next from "i18next";

const footer = ( props ) => (
<footer className="footer">
    {i18next.t("general.copyright")} <span className="text-muted d-none d-sm-inline-block float-right">{i18next.t("general.crafted")}</span>
</footer>
);

export default footer;
