import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

const pageSizes = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 50, label: '50'},
    {value: 100, label: '100'}
];

class PaginationSizes extends React.Component {

    state = {
        currentValue: 10
    }

    constructor(props) {
        super(props);
        this.state.currentValue = this.props.size;
    }

    render() {
        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons">

                {pageSizes.map((size, idx) => (
                    <label key={idx}
                        onClick={() => {
                            this.setState({currentValue: size.value}, () => { this.props.onChange(this.state.currentValue)})
                        }}
                        className={classNames("btn",
                            {"btn-primary active": this.state.currentValue === size.value},
                            {"btn-secondary": this.state.currentValue !== size.value})}>
                        <input type="radio" name="pagination_size"/> {size.label}
                    </label>
                ))}

            </div>
        )
    }
}

PaginationSizes.defaultProps = {
    size: 10
};

PaginationSizes.propTypes = {
    size: PropTypes.number,
    onChange: PropTypes.func,
}

export default PaginationSizes;
