import React from "react";
import i18next from "i18next";
import {Button, ModalFooter, ModalHeader, ModalBody} from "reactstrap";
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from "yup";
import superagent from "superagent";
import {AppContext} from "../App";
import {getLoggedInUser} from "../helpers/authUtils";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-notifications/lib/notifications.css';
import classNames from "classnames";
import moment from "moment";
import fr from 'date-fns/locale/fr';

class SitePage extends React.Component {

    state = {
        general_error: "",
        parameters: null
    };

    componentDidMount() {
        this.getParameters()
    }

    getParameters = () => {
        superagent
            .get(AppContext.hostName + '/v1/parameters')
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        parameters: res.body
                    })
                } else {
                    console.error(err);
                }
            });
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        const item = {
            "status": values["status"],
            "reopening_time": moment(values["reopening_time"]).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        }

        superagent
            .put(AppContext.hostName + '/v1/parameters/site')
            .send(JSON.stringify(item))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .set('Authorization', getLoggedInUser().access_token)
            .end((err, res) => {
                if (res === undefined) {
                    this.setState({general_error: err.message})
                } else {
                    switch (res.status) {
                        case 200:
                            NotificationManager.success(i18next.t("parameter.update-success"));
                            this.getParameters()
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                }
                setSubmitting(false);
            });
    };

    render = () => {
        if (this.state.parameters == null) {
            return null
        }

        registerLocale('fr', fr)

        const SiteSchema = Yup.object().shape({
            status: Yup.number(),
            reopening_time: Yup.date()
        });

        const initValues = {};
        initValues.status = this.state.parameters.site.status;
        initValues.reopening_time = this.state.parameters.site.reopening_time === "0001-01-01T00:00:00Z" ? null : moment(this.state.parameters.site.reopening_time).toDate()

        return (
            <React.Fragment>
                <NotificationContainer/>

                <div className="page-content-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">

                                <div className="card m-b-20">
                                    <div className="card-body">

                                        <Formik
                                            initialValues={initValues}
                                            validationSchema={SiteSchema}
                                            onSubmit={this.handleSubmit}
                                        >
                                            {({ values, setFieldValue, isValid, isSubmitting }) => (
                                                <Form>

                                                    <ModalHeader toggle={this.props.toggle}>
                                                        {i18next.t("site.title")}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        {this.state.general_error &&
                                                        <div className="alert alert-danger" role="alert">
                                                            <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                                                        </div>
                                                        }

                                                        <p className="text-muted m-b-30">{i18next.t("site.help")}</p>

                                                        <div className="form-group">
                                                            <label>{i18next.t("site.status")}</label>
                                                            <p className="text-muted m-b-15">{i18next.t("site.status-help")}</p>

                                                            <div className="btn-group btn-group-toggle" >
                                                                <label className={classNames("btn",
                                                                    {
                                                                        "btn-warning": values["status"] === 20,
                                                                        "btn-secondary": values["status"] !== 20,
                                                                        "active": values["status"] === 20,
                                                                    })
                                                                }>
                                                                    <input type="radio"
                                                                           onClick={() => {setFieldValue("status", 20)}}
                                                                    /> {i18next.t("site.under-construction")}
                                                                </label>
                                                                <label className={classNames("btn",
                                                                    {
                                                                        "btn-danger": values["status"] === 30,
                                                                        "btn-secondary": values["status"] !== 30,
                                                                        "active": values["status"] === 30,
                                                                    })
                                                                }>
                                                                    <input type="radio"
                                                                           onClick={() => {setFieldValue("status", 30)}}
                                                                    /> {i18next.t("site.under-maintenance")}
                                                                </label>
                                                                <label className={classNames("btn",
                                                                    {
                                                                        "btn-success": values["status"] === 10,
                                                                        "btn-secondary": values["status"] !== 10,
                                                                        "active": values["status"] === 10,
                                                                    })
                                                                }>
                                                                    <input type="radio"
                                                                           onClick={() => {setFieldValue("status", 10)}}
                                                                    /> {i18next.t("site.on-line")}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>{i18next.t("site.reopening-time")}</label>
                                                            <p className="text-muted m-b-15">{i18next.t("site.reopening-time-help")}</p>
                                                            <DatePicker
                                                                className="form-control"
                                                                locale={i18next.language}
                                                                showTimeSelect
                                                                selected={values["reopening_time"]}
                                                                onChange = {(date) => {
                                                                    setFieldValue("reopening_time", date)
                                                                }}
                                                                dateFormat={i18next.t("general.date-time-format")}
                                                            />
                                                            <ErrorMessage name={"email"} component="div" className="error-message"/>
                                                        </div>

                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                                            {isSubmitting && (
                                                                <i
                                                                    className="fa fa-refresh fa-spin"
                                                                    style={{ marginRight: "5px" }}
                                                                />
                                                            )}
                                                            {i18next.t("general.modify")}
                                                        </Button>{" "}
                                                    </ModalFooter>
                                                </Form>

                                            )}
                                        </Formik>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SitePage;