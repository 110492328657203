import React from "react";
import { Redirect } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import RecoverPasswordPage from "../pages/RecoverPasswordPage";
import CategoriesPage from "../pages/CategoriesPage";
import TagsPage from "../pages/TagsPage";
import TestimonialsPage from "../pages/TestimonialsPage";
import PostsPage from "../pages/PostsPage";
import AddressesPage from "../pages/AdressesPage";
import SchedulePage from "../pages/SchedulePage";
import PractitionerPage from "../pages/PractitionerPage";
import SitePage from "../pages/SitePage";
import UsersPage from "../pages/UsersPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import FAQPage from "../pages/FAQPage";

const authProtectedRoutes = [
    { path: "/addresses", component: AddressesPage },
    { path: "/categories", component: CategoriesPage },
    { path: "/faq", component: FAQPage },
    { path: "/home", component: HomePage },
    { path: "/posts", component: PostsPage },
    { path: "/practitioner", component: PractitionerPage },
    { path: "/schedule", component: SchedulePage },
    { path: "/site", component: SitePage },
    { path: "/tags", component: TagsPage },
    { path: "/testimonials", component: TestimonialsPage },
    { path: "/users", component: UsersPage },
    { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

const publicRoutes = [
    { path: "/login", component: LoginPage },
    { path: "/recover-password", component: RecoverPasswordPage },
    { path: "/reset-password/:token", component: ResetPasswordPage },
    { path: "*", component: NotFoundPage },
];

export { authProtectedRoutes, publicRoutes };
