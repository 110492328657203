import React from "react";
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {AppContext} from "../App";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {getLoggedInUser} from "../helpers/authUtils";
import i18next from "i18next";
import Slim from "../components/slim/slim.react";
import superagent from "superagent";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Select from "react-select";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

class PostForm extends React.Component {

    state = {
        categories: [],
        tags: [],
        post: null,
        slim: null,
        currentPicture: null
    };

    constructor(props) {
        super(props);
        this.state.post = this.props.post;
    }

    componentDidMount() {
        this.getCategories();
        this.getTags()
    }

    getCategories = () => {
        superagent
            .get(AppContext.hostName + '/v1/categories')
            .forceUpdate(false)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        general_error: "",
                        totalPage: parseInt(res.headers['x-pagination-page-count'], 10),
                        categories: res.body,
                        totalItemCount: parseInt(res.headers['x-pagination-total-count'], 10),
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    getTags = () => {
        superagent
            .get(AppContext.hostName + '/v1/tags')
            .forceUpdate(false)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    this.setState({
                        general_error: "",
                        totalPage: parseInt(res.headers['x-pagination-page-count'], 10),
                        tags: res.body,
                        totalItemCount: parseInt(res.headers['x-pagination-total-count'], 10),
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    handleResponse = (err, res, setErrors, setSubmitting) => {
        if (res === undefined) {
            this.setState({general_error: err.message})
        } else {
            switch (res.status) {
                case 200:
                    this.props.load();
                    this.props.toggle(true);
                    break;
                case 400:
                    const error = JSON.parse(res.text);
                    error.fields.forEach(function(field) {
                        if (field.name === "name") {
                            setErrors({ "name_en": field.message });
                        } else {
                            setErrors({ [field.name]: field.message });
                        }
                    });
                    break;
                case 401:
                    this.props.history.push('/login');
                    break;
                default:
                    this.setState({general_error: "Response status: " + res.status})
                    break;
            }
        }
        setSubmitting(false);
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        let item = {
            "status": values['status'],
            "title": values['title'],
            "summary": values['summary'],
            "text": values['text'],
            "category": values['category'],
            "tags": [],
            "action": 0,
            "base64": "",
        }

        values['tags'].forEach(tag => {
            item["tags"].push(tag.id)
        });

        // if picture deleted
        if (this.state.slim.dataBase64.output.name == null) {
            item["action"] = 2;
        } else {
            if (this.state.slim.dataBase64.output.image !== this.state.currentPicture) {
                item["action"] = 1;
                item["base64"] = this.state.slim.dataBase64.output.image;
            }
        }

        if (this.state.post.id !== "") {
            superagent
                .put(AppContext.hostName + '/v1/posts/' + this.state.post.id)
                .send(JSON.stringify(item))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        } else {
            superagent
                .post(AppContext.hostName + '/v1/posts')
                .send(JSON.stringify(item))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        }
    };

    getTitle = () => {
        if (this.state.post.id === "") {
            return (
                i18next.t("general.add-new-modal-title")
            );
        } else {
            return (
                i18next.t("general.update-modal-title")
            );
        }
    };

    slimInit(data, slim) {
        this.setState({
            slim: slim,
            currentPicture: slim.dataBase64 == null ? null : slim.dataBase64.output.image
        });
    }

    render = () => {
        if (this.state.categories.length < 1 || this.state.tags.length < 1) {
            return null
        }

        const PostSchema = Yup.object().shape({
            status: Yup.number(),
            title: Yup.string().required(i18next.t("error.field-required")),
            summary: Yup.string().required(i18next.t("error.field-required")),
            text: Yup.string(),
            category: Yup.string().required(i18next.t("error.field-required")),
            tags: Yup.array()
        });

        const initValues = {};
        initValues.status = this.state.post != null ? this.state.post.status : 0;
        initValues.title = this.state.post != null ? this.state.post.title : "";
        initValues.summary = this.state.post != null ? this.state.post.summary : "";
        initValues.text = this.state.post != null ? this.state.post.text : "";
        initValues.category = this.state.post != null ? this.state.post.category.id : "";
        initValues.tags = this.state.post != null ? this.state.post.tags : [];

        return (
            <Formik
                initialValues={initValues}
                validationSchema={PostSchema}
                onSubmit={this.handleSubmit}
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => (
                    <Form>

                        <ModalHeader toggle={this.props.toggle}>
                            {this.getTitle()}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.general_error &&
                            <div className="alert alert-danger" role="alert">
                                <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                            </div>
                            }

                            <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="nav-tabs-custom mb-3">
                                <Tab eventKey="general" title={i18next.t("post.general")}>


                                    <div className="form-group">
                                        <label>{i18next.t("post.published")}</label>
                                        <div>
                                            <input type="checkbox" id="status" switch="bool"
                                                   checked={values["status"] === 10 ? "checked" : ""}
                                                   onChange={() => {
                                                       if (values["status"] === 10) {
                                                           setFieldValue("status", 0)
                                                       } else {
                                                           setFieldValue("status", 10)
                                                       }
                                                   }}/>
                                            <label htmlFor="status" data-on-label={i18next.t("general.yes")} data-off-label={i18next.t("general.no")}/>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>{i18next.t("post.title")}</label>
                                        <Field name={"title"} className="form-control"
                                               value={values['title']}
                                               onChange={(e) => setFieldValue("title", e.target.value)}
                                        />
                                        <ErrorMessage name={"title"} component="div" className="error-message"/>
                                    </div>

                                    <div className="form-group">
                                        <label>{i18next.t("post.category")}</label>
                                        <Select name="category"
                                                placeholder={i18next.t("post.placeholder-category")}
                                                options={this.state.categories}
                                                defaultValue={this.state.categories.filter(category => category.id === values.category)[0]}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }}
                                                getOptionValue={(option) => {
                                                    return option.id
                                                }}
                                                onChange={(option) => {
                                                    if (option != null) {
                                                        setFieldValue("category", option.id, true)
                                                    } else {
                                                        setFieldValue("category", "", true)
                                                    }
                                                }}
                                        />
                                        <ErrorMessage name="category" component="div" className="error-message"/>
                                    </div>

                                    <div className="form-group">
                                        <label>{i18next.t("post.tags")}</label>
                                        <Select name="tags"
                                                isMulti
                                                placeholder={i18next.t("post.placeholder-tags")}
                                                options={this.state.tags}
                                                defaultValue={this.state.post.tags}
                                                getOptionLabel={(option) => {
                                                    return option.name
                                                }}
                                                getOptionValue={(option) => {
                                                    return option.id
                                                }}
                                                onChange={(option) => {
                                                    if (option != null) {
                                                        setFieldValue("tags", option, true)
                                                    } else {
                                                        setFieldValue("tags", [], true)
                                                    }
                                                }}
                                        />
                                        <ErrorMessage name="tags" component="div" className="error-message"/>
                                    </div>

                                    <div className="form-group">
                                        <label>{i18next.t("post.summary")}</label>
                                        <Field name={"summary"} className="form-control" component="textarea" rows="6"
                                               value={values['summary']}
                                               onChange={(e) => setFieldValue("summary", e.target.value)}
                                        />
                                        <ErrorMessage name={"summary"} component="div" className="error-message"/>
                                    </div>
                                </Tab>

                                <Tab eventKey="text" title={i18next.t("post.text")}>
                                    <div className="form-group">
                                        <label>{i18next.t("post.text")}</label>
                                        <ReactQuill
                                            value={values['text']}
                                            onChange={(value) => {
                                                setFieldValue("text", value)
                                            }}
                                        />
                                        <ErrorMessage name={"text"} component="div" className="error-message"/>
                                    </div>
                                </Tab>

                                <Tab eventKey="image" title={i18next.t("post.image")}>
                                    <div className="form-group">
                                        <label>{i18next.t("post.image")}</label>
                                        <Slim
                                            ratio={"1460:1048"}
                                            initialImage={this.state.post.image}
                                            push={false}
                                            download={true}
                                            label={i18next.t("slim.drop-here")}
                                            buttonEditTitle={i18next.t("slim.edit")}
                                            buttonRemoveTitle={i18next.t("slim.remove")}
                                            buttonDownloadTitle={i18next.t("slim.download")}
                                            buttonUploadTitle={i18next.t("slim.upload")}
                                            buttonRotateTitle={i18next.t("slim.rotate")}
                                            buttonCancelLabel={i18next.t("slim.cancel")}
                                            buttonCancelTitle={i18next.t("slim.cancel")}
                                            buttonConfirmLabel={i18next.t("slim.confirm")}
                                            buttonConfirmTitle={i18next.t("slim.confirm")}
                                            statusUploadSuccess={i18next.t("slim.saved")}
                                            minSize={{width: 570, height: 430}}
                                            serviceFormat="file"
                                            didInit={ this.slimInit.bind(this) }
                                        >
                                            <input type="file" name="foo"/>
                                        </Slim>

                                        <ErrorMessage name="genre" component="div" className="error-message"/>
                                    </div>
                                </Tab>
                            </Tabs>


                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                outline disabled={isSubmitting}
                                onClick={this.props.toggle}
                            >
                                {i18next.t("general.cancel")}
                            </Button>
                            <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                {isSubmitting && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {i18next.t("general.submit")}
                            </Button>{" "}
                        </ModalFooter>
                    </Form>

                )}
            </Formik>
        )
    }
}

PostForm.propTypes = {
    post: PropTypes.object,
    load: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
};

export default PostForm;