import React from "react";
import { Link } from 'react-router-dom';
import i18next from "i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {setLoggedInUser, rememberMe, forgetMe, getSavedEmail} from '../helpers/authUtils';
import {AppContext} from "../App";
import superagent from "superagent";

class LoginPage extends React.Component {

    state = {
        general_error: null
    }

    handleSubmit = async (values, { setErrors }) => {
        this.setState({general_error: null})

        superagent
            .post(AppContext.hostName + '/v1/users/login')
            .send(JSON.stringify(values))
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    setLoggedInUser(res.body)
                    if (values.rememberMe) {
                        rememberMe(values.email)
                    } else {
                        forgetMe(values.email)
                    }
                    this.props.history.push('/home');
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 400:
                        case 422:
                            const obj = JSON.parse(res.text);
                            obj.fields.forEach(function(element) {
                                setErrors({ [element.name]: element.message });
                            });
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    }

    render = () => {
        const startYear = 2021;
        const currentYear = new Date().getFullYear();
        let labelYear = startYear.toString()
        if (currentYear !== startYear) {
            labelYear = startYear.toString() + " - " + currentYear.toString()
        }

        return (
            <React.Fragment>
                <div className="accountbg" style={{ background : "url('/assets/images/bg-login.jpg')",backgroundSize : "cover" }} />
                <div className="wrapper-page account-page-full">
                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link to="/" onClick={()=> this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="/assets/images/logo.svg" height="90" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="font-18 m-b-5 text-center">{i18next.t("login.title")}</h4>
                                <p className="text-muted text-center">{i18next.t("login.subtitle")}</p>

                                <Formik
                                    className="form-wrap"
                                    initialValues={{
                                        email: getSavedEmail() ? getSavedEmail() : "",
                                        password: "",
                                        rememberMe: true
                                    }}
                                    onSubmit={this.handleSubmit}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email(i18next.t("error.invalid-email"))
                                            .required(i18next.t("error.field-required")),
                                        password: Yup.string()
                                            .required(i18next.t("error.field-required")),
                                        rememberMe: Yup.bool()
                                    })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            setFieldValue,
                                        } = props;
                                        return (
                                            <Form className="form-horizontal m-t-30">
                                                {this.state.general_error &&
                                                <div className="alert alert-danger m-t-30" role="alert">
                                                    <strong>{i18next.t("error.general")}</strong> {this.state.general_error}
                                                </div>
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="email">{i18next.t("login.email")}</label>
                                                    <Field type="email" className="form-control" name="email" placeholder={i18next.t("login.email-placeholder")} />
                                                    <ErrorMessage name="email" className="error-message" component="div" />
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="password">{i18next.t("login.password")}</label>
                                                    <Field type="password" className="form-control" name="password" placeholder={i18next.t("login.password-placeholder")} />
                                                    <ErrorMessage name="password" className="error-message" component="div" />
                                                </div>

                                                <div className="form-group row m-t-20">
                                                    <div className="col-sm-6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="rememberMe" checked={values.rememberMe}
                                                                   onClick={() => {setFieldValue("rememberMe", !values.rememberMe)}}/>
                                                            <label className="custom-control-label" htmlFor="rememberMe">{i18next.t("login.remember-me")}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 text-right">
                                                        <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{i18next.t("login.submit")}</button>
                                                    </div>
                                                </div>

                                                <div className="form-group m-t-10 mb-0 row">
                                                    <div className="col-12 m-t-20">
                                                        <Link to="/recover-password" className="text-muted"><i className="mdi mdi-lock"/> {i18next.t("login.forgot-password")}</Link>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>

                            </div>
                        </div>
                    </div>

                    <div className="m-t-40 text-center">
                        <p className="">{i18next.t("general.copyright", {labelYear: labelYear})}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LoginPage;