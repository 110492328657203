import React from "react";
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {AppContext} from "../App";
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {getLoggedInUser} from "../helpers/authUtils";
import i18next from "i18next";
import superagent from "superagent";
import Select from "react-select";
import TimePicker from 'react-time-picker';

class ScheduleForm extends React.Component {

    state = {
        general_error: "",
        schedule: null,
        slim: null,
        addresses: []
    };

    constructor(props) {
        super(props);
        this.state.schedule = this.props.schedule;
    }

    componentDidMount() {
        this.getAddresses()
    }

    getAddresses = () => {
        superagent
            .get(AppContext.hostName + '/v1/addresses')
            .forceUpdate(false)
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', i18next.language)
            .end((err, res, key) => {
                if (err == null) {
                    console.log(res.body);
                    this.setState({
                        general_error: "",
                        totalPage: parseInt(res.headers['x-pagination-page-count'], 10),
                        addresses: res.body,
                        totalItemCount: parseInt(res.headers['x-pagination-total-count'], 10),
                    });
                } else if (res !== undefined) {
                    switch (res.status) {
                        case 401:
                            this.props.history.push('/login');
                            break;
                        default:
                            this.setState({general_error: "Response status: " + res.status})
                            break;
                    }
                } else {
                    this.setState({general_error: err.message})
                }
            });
    };

    handleResponse = (err, res, setErrors, setSubmitting) => {
        if (res === undefined) {
            this.setState({general_error: err.message})
        } else {
            switch (res.status) {
                case 200:
                    this.props.load();
                    this.props.toggle(true);
                    break;
                case 400:
                    const error = JSON.parse(res.text);
                    error.fields.forEach(function(field) {
                        if (field.name === "name") {
                            setErrors({ "name_en": field.message });
                        } else {
                            setErrors({ [field.name]: field.message });
                        }
                    });
                    break;
                //case 401:
                //    this.props.history.push('/login');
                //    break;
                default:
                    this.setState({general_error: "Response status: " + res.status})
                    break;
            }
        }
        setSubmitting(false);
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        console.log(values);

        if (values["id"] !== "") {
            superagent
                .put(AppContext.hostName + '/v1/schedule')
                .send(JSON.stringify(values))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});

        } else {
            superagent
                .post(AppContext.hostName + '/v1/schedule')
                .send(JSON.stringify(values))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        }
    };

    getTitle = () => {
        if (this.state.schedule.id === "") {
            return (
                i18next.t("general.add-new-modal-title")
            );
        } else {
            return (
                i18next.t("general.update-modal-title")
            );
        }
    };

    render = () => {
        if (this.state.addresses.length < 1) {
            return null
        }

        const days = [
            {"id": "monday", "name": i18next.t("schedule.monday")},
            {"id": "tuesday", "name": i18next.t("schedule.tuesday")},
            {"id": "wednesday", "name": i18next.t("schedule.wednesday")},
            {"id": "thursday", "name": i18next.t("schedule.thursday")},
            {"id": "friday", "name": i18next.t("schedule.friday")},
            {"id": "saturday", "name": i18next.t("schedule.saturday")},
            {"id": "sunday", "name": i18next.t("schedule.sunday")}
        ];

        const ScheduleSchema = Yup.object().shape({
            day: Yup.string().required(i18next.t("error.field-required")),
            id: Yup.string(),
            address: Yup.string().required(i18next.t("error.field-required")),
            start: Yup.string().required(i18next.t("error.field-required")),
            finish: Yup.string().required(i18next.t("error.field-required"))
        });

        const initValues = {};
        initValues.day = this.state.schedule != null ? this.state.schedule.day : "";
        initValues.id = this.state.schedule != null ? this.state.schedule.id : "";
        initValues.address = this.state.schedule != null ? this.state.schedule.address : "";
        initValues.start = this.state.schedule != null ? this.state.schedule.start : "08:00";
        initValues.finish = this.state.schedule != null ? this.state.schedule.finish : "18:00";

        console.log(initValues);

        return (
            <Formik
                initialValues={initValues}
                validationSchema={ScheduleSchema}
                onSubmit={this.handleSubmit}
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => (
                    <Form>

                        <ModalHeader toggle={this.props.toggle}>
                            {this.getTitle()}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.general_error &&
                            <div className="alert alert-danger" role="alert">
                                <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                            </div>
                            }

                            <div className="form-group">
                                <label>{i18next.t("schedule.day")}</label>
                                <Select name="day"
                                        placeholder={i18next.t("schedule.placeholder-day")}
                                        options={days}
                                        defaultValue={days.filter(day => day.id === values.day)[0]}
                                        getOptionLabel={(option) => {
                                            return option.name
                                        }}
                                        getOptionValue={(option) => {
                                            return option.id
                                        }}
                                        onChange={(option) => {
                                            if (option != null) {
                                                console.log(option.id);
                                                setFieldValue("day", option.id, true)
                                            } else {
                                                setFieldValue("day", "", true)
                                            }
                                        }}
                                />
                                <ErrorMessage name="day" component="div" className="error-message"/>
                            </div>

                            <div className="form-group">
                                <label>{i18next.t("schedule.address")}</label>
                                <Select name="address"
                                        placeholder={i18next.t("schedule.placeholder-address")}
                                        options={this.state.addresses}
                                        defaultValue={this.state.addresses.filter(address => address.id === values.address)[0]}
                                        getOptionLabel={(option) => {
                                            return option.city
                                        }}
                                        getOptionValue={(option) => {
                                            return option.id
                                        }}
                                        onChange={(option) => {
                                            if (option != null) {
                                                setFieldValue("address", option.id, true)
                                            } else {
                                                setFieldValue("address", "", true)
                                            }
                                        }}
                                />
                                <ErrorMessage name="day" component="div" className="error-message"/>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>{i18next.t("schedule.start")}</label>
                                        <div>
                                            <TimePicker
                                                clearIcon={null}
                                                disableClock={true}
                                                onChange={(value) => {
                                                    console.log(value);
                                                    setFieldValue("start", value, true)
                                                }}
                                                value={values.start}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <label>{i18next.t("schedule.finish")}</label>
                                        <div>
                                            <TimePicker
                                                clearIcon={null}
                                                disableClock={true}
                                                onChange={(value) => {
                                                    console.log(value);
                                                    setFieldValue("finish", value, true)
                                                }}
                                                value={values.finish}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                outline disabled={isSubmitting}
                                onClick={this.props.toggle}
                            >
                                {i18next.t("general.cancel")}
                            </Button>
                            <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                {isSubmitting && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {i18next.t("general.submit")}
                            </Button>{" "}
                        </ModalFooter>
                    </Form>

                )}
            </Formik>
        )
    }
}

ScheduleForm.propTypes = {
    schedule: PropTypes.object,
    load: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
};

export default ScheduleForm;