import React from "react";
import {
    Button,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {AppContext} from "../App";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {getLoggedInUser} from "../helpers/authUtils";
import i18next from "i18next";
import superagent from "superagent";

class TagForm extends React.Component {

    state = {
        tag: null,
        slim: null
    };

    constructor(props) {
        super(props);
        this.state.tag = this.props.tag;
    }

    handleResponse = (err, res, setErrors, setSubmitting) => {
        if (res === undefined) {
            this.setState({general_error: err.message})
        } else {
            switch (res.status) {
                case 200:
                    this.props.load();
                    this.props.toggle(true);
                    break;
                case 400:
                    const error = JSON.parse(res.text);
                    error.fields.forEach(function(field) {
                        if (field.name === "name") {
                            setErrors({ "name_en": field.message });
                        } else {
                            setErrors({ [field.name]: field.message });
                        }
                    });
                    break;
                //case 401:
                //    this.props.history.push('/login');
                //    break;
                default:
                    this.setState({general_error: "Response status: " + res.status})
                    break;
            }
        }
        setSubmitting(false);
    }

    handleSubmit = (values, { setErrors, setSubmitting }) => {
        let item = {
            "name": values['name'],
        }

        if (this.state.tag.id !== "") {
            superagent
                .put(AppContext.hostName + '/v1/tags/' + this.state.tag.id)
                .send(JSON.stringify(item))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        } else {
            superagent
                .post(AppContext.hostName + '/v1/tags')
                .send(JSON.stringify(item))
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('Accept-Language', i18next.language)
                .set('Authorization', getLoggedInUser().access_token)
                .end((err, res) => {this.handleResponse(err, res, setErrors, setSubmitting)});
        }
    };

    getTitle = () => {
        if (this.state.tag.id ==="") {
            return (
                i18next.t("general.add-new-modal-title")
            );
        } else {
            return (
                i18next.t("general.update-modal-title")
            );
        }
    };

    render = () => {
        const TagSchema = Yup.object().shape({
            name: Yup.string().required(i18next.t("error.field-required"))
        });

        const initValues = {};
        initValues.name = this.state.tag != null ? this.state.tag.name : "";

        return (
            <Formik
                initialValues={initValues}
                validationSchema={TagSchema}
                onSubmit={this.handleSubmit}
            >
                {({ values, setFieldValue, isValid, isSubmitting }) => (
                    <Form>

                        <ModalHeader toggle={this.props.toggle}>
                            {this.getTitle()}
                        </ModalHeader>
                        <ModalBody>
                            {this.state.general_error &&
                            <div className="alert alert-danger" role="alert">
                                <strong>{i18next.t("general.error")}</strong> {this.state.general_error}
                            </div>
                            }


                            <div className="form-group">
                                <label>{i18next.t("tag.name")}</label>
                                <Field name={"name"} className="form-control"
                                       value={values['name']}
                                       onChange={(e) => setFieldValue("name", e.target.value)}
                                />
                                <ErrorMessage name={"name"} component="div" className="error-message"/>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="secondary"
                                outline disabled={isSubmitting}
                                onClick={this.props.toggle}
                            >
                                {i18next.t("general.cancel")}
                            </Button>
                            <Button color="primary" type="submit" disabled={!isValid || isSubmitting}>
                                {isSubmitting && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {i18next.t("general.submit")}
                            </Button>{" "}
                        </ModalFooter>
                    </Form>

                )}
            </Formik>
        )
    }
}

TagForm.propTypes = {
    tag: PropTypes.object,
    load: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired
};

export default TagForm;